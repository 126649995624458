<template>
  <aside :class="sidebar ? 'open-sidebar' : ''">
    <div class="logo">
      <img src="@/assets/images/ibbil/goldLogo.svg" width="180px" alt="" />
    </div>
    <ul class="sidebar-items p-0 mb-0 ">
      <li v-for="(item, i) in SideBarItems" :key="i">
        <router-link :to="{name: item.link.name}" tag="div" exact-active-class="active" class="sidebar-link-wrapper d-flex justify-content-between" v-if="!item.children">
          <div class="sidebar-link d-flex align-items-center">
            <i :class="item.icon"></i>
            <span>{{ $t(item.name) }}</span>
          </div>
          <div v-if="item.children" class="sidebar-arrow">
            <i
              class="las"
              :class="item.is_active ? 'la-angle-up' : 'la-angle-down'"
            ></i>
          </div>
        </router-link>
        <div v-else class="collapse_nav">
          <div class="sidebar-link-wrapper d-flex justify-content-between" :class="$route.path.includes(item.name.toLowerCase()) ? 'active' : ''" @click="openSubMenu(i)">
            <div class="sidebar-link d-flex align-items-center">
              <i :class="item.icon"></i>
              <span>{{ $t(item.name) }}</span>
            </div>
            <div v-if="item.children" class="sidebar-arrow">
              <i
                  class="las"
                  :class="item.is_active ? 'la-angle-up' : 'la-angle-down'"
              ></i>
            </div>
          </div>
          <collapse-sublinks
              @changeSelect="getSelectedRow"
              :sublinks="item.children"
              :collapse="item.is_active"
              :parentKey= "i"
          />
        </div>
      </li>
    </ul>
    <div class="help-logout">
      <ul class="px-0 m-0 pt-3">
        <router-link
          :to="{ name: 'support' }"
          tag="li"
          exact-active-class="active"
          class="support-item d-flex align-items-center"
        >
          <i class="las la-headset"></i>
          <span>الدعم الفني</span>
        </router-link>
        <b-button @click="logout" tag="li" class="d-flex align-items-center bg-transparent border-0">
          <i class="las la-sign-in-alt"></i>
          <span>تسجيل الخروج</span>
        </b-button>
      </ul>
    </div>
  </aside>
</template>

<script>
import SideBarItems from '@/FackApi/json/SideBar'
import CollapseSublinks from './CollapseSublinks.vue'
import EventBus from '@/eventBus.js'
import Scrollbar from 'smooth-scrollbar'

export default {
  components: { CollapseSublinks },
  data () {
    return {
      SideBarItems,
      sidebar: false
    }
  },
  methods: {
    getSelectedRow (data) {
      this.checkElementActive(data)
    },
    checkElementActive (item) {
      return item.children.some(i => i.is_active)
    },
    openSubMenu (index) {
      if ('children' in this.SideBarItems[index]) {
        this.SideBarItems[index].is_active =
          !this.SideBarItems[index].is_active
      }
    }
  },
  created () {
    EventBus.$on('toggleSidebar', (data) => {
      this.sidebar = data
    })
  },
  mounted () {
    Scrollbar.init(document.querySelector('.sidebar-items'))
  }
}
</script>

<style lang="scss">
.dashboard-layout {
  aside {
    position: fixed;
    top: 0;
    right: 0;
    padding: 32px 0;
    padding-inline-start: 32px;
    width: 320px;
    min-height: 100vh;
    height: 100vh;
    display: flex !important;
    justify-content: space-between;
    flex-direction: column;
    color: #fff;
    transition: 0.3s ease;
    background-color: var(--iq-primary-light);
    z-index: 10;
    li {
      font-size: 20px;
      margin-top: 4px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      i {
        font-size: 25px;
        margin-inline-end: 8px;
      }
    }
    .logo {
      padding-inline-start: 16px;
      margin-bottom: 3.5rem;
    }
    .sidebar-items {
      height: 75%;
      .sidebar-link-wrapper {
        border-radius: 0 10px 10px 0;
        padding: 8px 0;
        padding-inline-start: 16px;
        transition: 0.3s ease-in-out;
        &:hover {
          background: rgba($color: #fff, $alpha: 0.1);
        }
        &.active {
          background: #f6f6f6;
          color: var(--iq-primary);
        }
      }
      .sidebar-arrow {
        padding-inline-end: 16px;
        i {
          font-size: 18px;
        }
      }
    }
    .help-logout {
      margin-inline-end: 32px;
      border-top: 1px solid rgba($color: #fff, $alpha: 0.4);
      color: #b0bfb8;
      ul {
        li {
          padding: 4px 0;
          font-size: 18px;
          &:hover {
            color: var(--iq-warning);
          }
        }
      }
    }
    .scrollbar-track{
      border-radius: 20px;
      background: rgb(0 0 0 / 6%);
      left: 0;
      right: unset;
      margin-inline-end: 8px;
      .scrollbar-thumb{
        background: rgb(0 0 0 / 28%);
      }
    }
    @media (max-width: 500px) {
      padding-top: 24px;
      padding-bottom: 24px;
      width: 300px;
      right: -300px;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
      .logo{
        margin-bottom: 0;
      }
      .sidebar-items{
        height: 70%;
      }
      &.open-sidebar{
        right: 0;
      }
    }
  }
  .collapse_nav .router-link-active {
    background: rgba($color: #fff, $alpha: 0.1);
    padding: 12px;
    padding-inline-start: 16px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 12px;
  }
}
</style>
