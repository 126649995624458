<template>
  <div class="dashboard-layout">
    <navbar-dashboard />
    <sidebar-dashboard />
    <div class="dashboard-content-page">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NavbarDashboard from './NavbarDashboard.vue'
import SidebarDashboard from './SidebarDashboard.vue'

export default {
  components: { NavbarDashboard, SidebarDashboard },
  data () {
    return {
      rtl: false
    }
  },
  watch: {
    $route: function (to, from) {
    }
  },
  methods: {
    layoutSetting () {
      this.modeChange({ rtl: this.rtlMode })
    },
    ...mapActions({
      modeChange: 'Setting/layoutModeAction'
    })
  },
  mounted () {
    this.layoutSetting(this.$route.name)
    document.body.style.background = 'var(--iq-primary-light)'
    document.body.dir = 'rtl'
    document.documentElement.dir = 'rtl'
    document.documentElement.lang = 'ar'
  }
}
</script>

<style lang="scss">
@import url("../../assets/css/custom.css");

html {
  direction: rtl;
}

.dashboard-layout {
  background-color:  var(--iq-primary-light) !important;
  .dashboard-content-page {
    min-height: calc(100vh - 100px);
    padding: 48px 32px;
    margin-top: 100px;
    margin-inline-start: 320px;
    border-top-right-radius: 40px;
    background: #f6f6f6;
    color: #000;
    @media (max-width: 500px) {
      margin-top: 70px;
      margin-inline-start: 0;
      border-radius: 0;
    }
  }
}
</style>
