<template>
  <nav class="d-flex justify-content-between align-items-center">
    <div class="search-container">
      <i class="las la-search"></i>
      <input
        class="bg-transparent border-0"
        type="text"
        placeholder="... ابحث في لوحة التحكم"
      />
    </div>
    <div class="dashboard-controller-type text-center flex-grow-1">
      <h5 class="font-weight-bold text-white">لوحة تحكم المدير</h5>
    </div>
    <div class="user-notify-info d-flex align-items-center gap_1 text-white">
      <div class="user-notify position-relative">
        <userNotification />
<!--        <i class="lar la-bell"></i>-->
<!--        <div class="notify-badge">+99</div>-->
      </div>
      <div class="user-info d-flex align-items-center">
<!--        <div class="user-img">-->
<!--          <img src="@/assets/images/ibbil/images/user-profile.jpg" alt="" />-->
          <div class="user-cover"
               :style="{ 'background-image': 'url(' + userImg + ')' ,
           'background-size' :  'cover',
           'background-repeat': 'no-repeat'
      }"
          >
          </div>
<!--        </div>-->
        <div class="welcome-user">{{userData}}</div>
        <i class="las la-caret-down"></i>
      </div>
    </div>
    <div class="mobile-icons-wrapper align-items-center">
      <div class="user-notify position-relative">
        <userNotification />
      </div>
      <div class="search-icon">
        <i class="las la-search"></i>
      </div>
      <div class="burger-icon" @click="toggleSidebar()">
        <i :class="sidebar ? 'las la-times' : 'las la-bars'"></i>
      </div>
    </div>
  </nav>
</template>

<script>
import EventBus from '@/eventBus'
import userNotification from '@/layouts/Components/userNotification'
export default {
  components: { userNotification },
  data () {
    return {
      userData: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user.name : '',
      userImg: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user.avatar : '',
      sidebar: false
    }
  },
  methods: {
    toggleSidebar () {
      this.sidebar = !this.sidebar
      EventBus.$emit('toggleSidebar', this.sidebar)
    }
  }
}
</script>

<style lang="scss">
.user-cover{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.user-cover img{
  width: 100%;
  height: 100%;
  background-size: cover;
}
.dashboard-layout {
  nav {
    position: fixed;
    top: 0;
    right: 320px;
    width: calc(100% - 320px);
    padding: 24px 32px;
    background: var(--iq-primary-light);
    z-index: 5;
    color: #fff;
    .search-container {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: rgba($color: #f6f6f6, $alpha: 0.7);
      border-bottom: 1px solid;
      i {
        font-size: 22px;
      }
      ::placeholder {
        color: rgba($color: #f6f6f6, $alpha: 0.7);
      }
      input {
        color: #fff;
      }
    }
    .user-notify {
      i{
        font-size: 36px;
      }
      .notify-badge {
        position: absolute;
        top: -5px;
        right: -50%;
        background: var(--iq-warning);
        padding: 0 8px;
        border-radius: 5px;
        font-size: 11px;
      }
    }
    .user-notify-info {
      .user-info {
        gap: 5px;
        .user-img {
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
      }
    }
    .mobile-icons-wrapper{
      display: none ;
      gap: 0.5rem;
      .user-notify{
        .notify-badge{
          padding: 0 4px;
        }
      }
      i{
        font-size: 30px;
      }
    }
    @media (max-width: 500px) {
      width: 100%;
      right: 0;
      padding: 16px 24px;
      .search-container, .dashboard-controller-type{
        display: none;
      }
      .user-notify-info{
        .user-notify{
          display: none;
        }
      }
      .mobile-icons-wrapper{
        display: flex;
      }
    }
  }
}
</style>
